import { createEnv } from "@t3-oss/env-nextjs"
import { z } from "zod"

export const env = createEnv({
  server: {
    VERCEL_URL: z.string().default("localhost:3000"),
    VERCEL_GIT_COMMIT_REF: z.string().default("local"),
    SHOPIFY_TOKEN: z.string(),
    RECAPTCHA_SECRET: z.string(),
    NODE_ENV: z.enum(["development", "production", "test"]).default("development"),
    VERCEL_ENV: z.enum(["development", "preview", "production"]).default("development"),
    AUTH0_BASE_URL: z.string(),
    AUTH0_CLIENT_ID: z.string(),
    AUTH0_CLIENT_SECRET: z.string(),
    AUTH0_ISSUER_BASE_URL: z.string(),
    AUTH0_SECRET: z.string(),
    SENDGRID_API_KEY: z.string(),
    DAREL_FTP_HOST: z.string(),
    DAREL_FTP_USERNAME: z.string(),
    DAREL_FTP_PASSWORD: z.string(),
    DAREL_FTP_PORT: z.string(),
    DAREL_FTP_PREFIX: z.string(),
    INNGEST_SIGNING_KEY: z
      .string()
      .optional()
      .default("signkey-branch-848973d4fef03d2435bfd35d396479b15082a709731c795997a12cfae1aaceab"),
    INNGEST_EVENT_KEY: z
      .string()
      .optional()
      .default("OhiUwVR3WdiEz_hYjioYMvVXtXyQhO1tRnxwmSYnRFqenFgSDGdvo2K6bB3iBKfXm6hYuESeHHnns5v0fkqK1g"),
    EDGE_CONFIG: z.string().default(""),
    UPSTASH_HOST: z.string().default(""),
    UPSTASH_TOKEN: z.string().default(""),
    SOCLOZ_CLIENT_ID: z.string(),
    SOCLOZ_CLIENT_SECRET: z.string(),
    SOCLOZ_HOST: z.string(),
    MAKE_GCO_WEBHOOK_URL: z.string(),
  },
  client: {
    NEXT_PUBLIC_MAINTENANCE_MODE: z.string().default("false"),
    NEXT_PUBLIC_STORYBLOK_TOKEN: z.string(),
    NEXT_PUBLIC_VERCEL_URL: z.string().default("localhost:3000"),
    NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF: z.string().default("local"),
    NEXT_PUBLIC_RECAPTCHA_KEY: z.string(),
    NEXT_PUBLIC_SHOPIFY_TOKEN: z.string(),
    NEXT_PUBLIC_ENV: z.string(),
    NEXT_PUBLIC_GTM_ID: z.string(),
    NEXT_PUBLIC_AXEPTIO_PROJECT_ID: z.string().optional(),
    NEXT_PUBLIC_NODE_ENV: z.enum(["development", "production", "test"]).default("development"),
    NEXT_PUBLIC_CACHE_ENABLED: z.string().default("true"),
    NEXT_PUBLIC_SOCLOZ_APP_KEY: z.string(),
    NEXT_PUBLIC_SHIPUP_APP_KEY: z.string(),
    NEXT_PUBLIC_ZENDESK_KEY: z.string(),
    NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN: z.string(),
    NEXT_PUBLIC_NOSTO_MARKETS_CREDENTIALS: z.string(),
    NEXT_PUBLIC_VERCEL_ENV: z.enum(["development", "preview", "production"]).default("development"),
    NEXT_PUBLIC_BRAND_NAME: z.enum(["gerard-darel", "pablo"]).optional().default("gerard-darel"),
  },
  runtimeEnv: {
    NEXT_PUBLIC_MAINTENANCE_MODE: process.env.NEXT_PUBLIC_MAINTENANCE_MODE,
    NEXT_PUBLIC_SHOPIFY_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_TOKEN,
    NEXT_PUBLIC_STORYBLOK_TOKEN: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
    NEXT_PUBLIC_VERCEL_URL:
      process.env.NODE_ENV === "development" ? "http://localhost:3000" : process.env.NEXT_PUBLIC_VERCEL_URL,
    NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
    NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF,
    SHOPIFY_TOKEN: process.env.SHOPIFY_TOKEN,
    VERCEL_GIT_COMMIT_REF: process.env.VERCEL_GIT_COMMIT_REF,
    VERCEL_URL: process.env.VERCEL_URL,
    NEXT_PUBLIC_RECAPTCHA_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_KEY,
    RECAPTCHA_SECRET: process.env.RECAPTCHA_SECRET,
    NEXT_PUBLIC_VERCEL_ENV: process.env.NODE_ENV === "development" ? "development" : process.env.NEXT_PUBLIC_VERCEL_ENV,
    NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
    NEXT_PUBLIC_SOCLOZ_APP_KEY: process.env.NEXT_PUBLIC_SOCLOZ_APP_KEY,
    SOCLOZ_CLIENT_ID: process.env.SOCLOZ_CLIENT_ID,
    SOCLOZ_CLIENT_SECRET: process.env.SOCLOZ_CLIENT_SECRET,
    SOCLOZ_HOST: process.env.SOCLOZ_HOST,
    MAKE_GCO_WEBHOOK_URL: process.env.MAKE_GCO_WEBHOOK_URL,
    NEXT_PUBLIC_NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_CACHE_ENABLED: process.env.NEXT_PUBLIC_CACHE_ENABLED,
    NEXT_PUBLIC_ZENDESK_KEY: process.env.NEXT_PUBLIC_ZENDESK_KEY,
    NEXT_PUBLIC_AXEPTIO_PROJECT_ID: process.env.NEXT_PUBLIC_AXEPTIO_PROJECT_ID,
    NEXT_PUBLIC_SHIPUP_APP_KEY: process.env.NEXT_PUBLIC_SHIPUP_APP_KEY,
    NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN,
    NEXT_PUBLIC_NOSTO_MARKETS_CREDENTIALS: process.env.NEXT_PUBLIC_NOSTO_MARKETS_CREDENTIALS,
    AUTH0_BASE_URL: process.env.NODE_ENV === "development" ? "http://localhost:3000" : process.env.AUTH0_BASE_URL,
    AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
    AUTH0_CLIENT_SECRET: process.env.AUTH0_CLIENT_SECRET,
    AUTH0_ISSUER_BASE_URL: process.env.AUTH0_ISSUER_BASE_URL,
    AUTH0_SECRET: process.env.AUTH0_SECRET,
    SENDGRID_API_KEY: process.env.SENDGRID_API_KEY,
    DAREL_FTP_HOST: process.env.DAREL_FTP_HOST,
    DAREL_FTP_USERNAME: process.env.DAREL_FTP_USERNAME,
    DAREL_FTP_PASSWORD: process.env.DAREL_FTP_PASSWORD,
    DAREL_FTP_PORT: process.env.DAREL_FTP_PORT,
    DAREL_FTP_PREFIX: process.env.DAREL_FTP_PREFIX,
    INNGEST_SIGNING_KEY: process.env.INNGEST_SIGNING_KEY,
    INNGEST_EVENT_KEY: process.env.INNGEST_EVENT_KEY,
    EDGE_CONFIG: process.env.EDGE_CONFIG,
    UPSTASH_HOST: process.env.UPSTASH_HOST,
    UPSTASH_TOKEN: process.env.UPSTASH_TOKEN,
    VERCEL_ENV: process.env.VERCEL_ENV,
    NEXT_PUBLIC_BRAND_NAME: process.env.NEXT_PUBLIC_BRAND_NAME,
  },
})

export type Env = typeof env
